import base from './baseApi';

const getPlans = () => {
  const { urls, methods, execute } = base();
  const method = methods.GET;
  const url = `${urls.plans}/?page=0&limit=0`;

  const response = execute(method, url, {});

  return response;
};

const createSubscriptions = async (payload: { planId: string, userId: string, stripeId?: string }) => {
  const { urls, methods, execute } = base();
  const method = methods.POST;
  const url = urls.subscriptions;
  const response = await execute(method, url, payload);

  return response;
};

const createOrgAdminSubscription = async () => {
  const { urls, methods, execute } = base();
  const method = methods.POST;
  const url = urls.stripes.createOrgAdminSubscription;
  const response = await execute(method, url);

  return response;
}
const createSubscriptionAdmin = async (payload: { planId: string, userId: string, expiredAt: string }) => {
  const { urls, methods, execute } = base();
  const method = methods.POST;
  const url = `${urls.subscriptions}/admin`;
  const response = await execute(method, url, payload);

  return response;
};

const getSubscription = async (stripeCustomerId: string) => {
  const { urls, methods, execute } = base();
  const method = methods.GET;
  const url = `${urls.stripes.getSubscription}?stripeCustomerId=${stripeCustomerId}`;
  const response = await execute(method, url);

  return response;
}
export { getPlans, createSubscriptions, createSubscriptionAdmin, createOrgAdminSubscription, getSubscription };
