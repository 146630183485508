import base from './baseApi';

const createPaymentIntent = async (payload: { planId: string, amount: number }) => {
  const { urls, methods, execute } = base();
  const method = methods.POST;
  const url = urls.stripes.stripes;
  const response = await execute(method, url, payload);

  return response;
};

const chargeSubscription = async () => {
  const { urls, methods, execute } = base();
  const method = methods.POST;
  const url = urls.stripes.chargeSubscription;
  const response = await execute(method, url);

  return response;
};

const confirmPayment = async (payload: { paymentIntentId: string }) => {
  const { urls, methods, execute } = base();
  const method = methods.PUT;
  const url = urls.stripes.confirm;
  const response = await execute(method, url, payload);

  return response;
};

const getCustomerPortal = async () => {
  const { urls, methods, execute } = base();
  const method = methods.GET;
  const url = urls.stripes.customerPortal;
  return  await execute(method, url);
};

export { createPaymentIntent, confirmPayment, chargeSubscription, getCustomerPortal };
