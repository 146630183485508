/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import { Textarea, TextInput } from 'flowbite-react';
import { useEffect, useState } from 'react';
import './style.scss';
import { themInput as theme } from 'utils/theme';

const InputText = (props: any) => {
  const { name, hookForm, isHolderLabel, disabled, className, type = 'text', rows = 3, rules = {} } = props;
  const { getValues, register } = hookForm;
  const errors = hookForm.formState.errors;
  const [focus, setFocus] = useState(props.focus || !!getValues(name));
  const placeholder = !isHolderLabel ? props.placeholder : '';
  const color = errors?.[name] ? 'failure' : 'gray';
  const helperText = <span className="text-xs font-normal">{errors?.[name]?.message}</span>;

  const paramsInput = { placeholder, disabled, color, helperText, theme, type };
  const paramsArea = { placeholder, disabled, color, helperText, theme, rows };

  useEffect(() => {
    if (!!getValues(name) || getValues(name) === 0) {
      setFocus(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues(name)])

  return (
    <div className={`flex-1 input-text relative ${className || ''}`} onFocus={() => setFocus(true)} onBlur={() => setFocus(!!getValues(name))}>
      {isHolderLabel && <span className={`${focus ? 'label': ''} ${type === 'textarea' ? 'holder-textarea' : 'holder'}`}>{props.placeholder}</span>}
      {type === 'textarea' ? <Textarea {...register(name, rules)} {...paramsArea} /> : <TextInput {...register(name, rules)} {...paramsInput} />}
      {props.rightIcon && (
        <div
          onClick={() => !disabled && props.handleClick && props.handleClick()}
          className="cursor-pointer absolute right-icon right-1 top-[12px] w-[45px] h-[25px]"
        >
          {props.rightIcon}
        </div>
      )}
    </div>
  );
};

export default InputText;
