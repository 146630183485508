export const themeTabs: any = {
  tablist: {
    styles: { underline: 'border-b pl-8' },
    tabitem: {
      styles: {
        underline: { active: { on: 'border-b-2 border-blue-600 text-blue-600 focus:ring-0 px-8', off: 'border-blue-600 focus:ring-0 px-8' } },
      },
    },
  },
};

export const themTooltip = {
  target: 'w-full overflow-hidden text-ellipsis whitespace-nowrap',
  base: 'bg-blue-600 ',
  style: {
    dark: 'bg-gray-800 bg-opacity-80 text-white px-2 py-1 !left-14 !top-8 rounded',
  },
};

export const themModal = {
  content: {
    base: 'relative w-full p-4 md:h-auto',
  },
  body: { base: 'flex-1 px-12 !py-8' },
};

export const themInput = {
  field: {
    input: {
      colors: {
        gray: 'border bg-white border-border',
      },
      withAddon: { off: 'rounded-md' },
    },
  },
};

export const themDatepicker = {
  views: {
    days: {
      header: {
        base: 'grid grid-cols-7 mb-1',
        title: 'dow h-6 text-center text-sm font-medium leading-6 text-gray-500 dark:text-gray-400',
      },
      items: {
        base: 'grid w-64 grid-cols-7',
        item: { selected: 'bg-primary !text-white hover:bg-cyan-600', disabled: 'text-gray-500' },
      },
    },
  },
};


