import { Button } from "flowbite-react"
import InputText from 'components/inputText';
import { HiOutlinePencil } from 'react-icons/hi';
import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from "react-i18next";
import './updatePatient.scss'
import { isArray, omit, pick } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import { toast } from 'react-toastify';
import { updateOrganizationUser } from "api/userApi";
import useUser from "hooks/useUser";
import { useSelector } from "react-redux";
import { userSelector } from "redux/selectors";
import { useParams } from "react-router-dom";
import { handleTrimString } from "utils/utils";
import SpinnerComponent from '../spinner';

const UpdatePatient = (props: any) => {
    const { targetData, messageUpdate } = props
    const [isEdit, setIsEdit] = useState(false)
    const [isEditEmail, setIsEditEmail] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [t] = useTranslation()
    const { fetchCurrentUser } = useUser();
    const { userInfo } = useSelector(userSelector);
    const params: any = useParams();
    const queryClient = useQueryClient();

    const ValidateSchemaFullName = Yup.object().shape({
        firstName: Yup.string().max(100, t('userManagementPage.nameLengthMax')).nullable().required(t('userManagementPage.requiredField')),
        lastName: Yup.string().max(100, t('userManagementPage.nameLengthMax')).nullable().required(t('userManagementPage.requiredField')),
    });

    const handleSuccess = (message: string) => {
        if (messageUpdate) {
            toast.success(messageUpdate);
        } else toast.success(message);
        if (params?.id === userInfo.id) fetchCurrentUser();
    };

    const handleSubmitUser = (data: any) => {
        return updateOrganizationUser(data)
    };

    const muUpdateUser = useMutation('update-usesOrg', {
        mutationFn: handleSubmitUser,
        onSuccess: () => {
            queryClient.invalidateQueries('getOrganizationTherapists', { refetchActive: true }, { cancelRefetch: true });
            queryClient.invalidateQueries('getPatientProfile', { refetchActive: true }, { cancelRefetch: true });
            queryClient.invalidateQueries('getPatients', { refetchActive: true }, { cancelRefetch: true });
            queryClient.invalidateQueries('getTherapist', { refetchActive: true }, { cancelRefetch: true });
            handleSuccess(t('userManagementPage.editSuccessMessage'));
            setIsLoading(false);
        },
        onError: async (error: any) => {
            const message: string = `${error.response.data.errors?.[0].detail}`;
            toast.error(message);
            setIsLoading(false);
        },
    });

    const onSubmitName = (payload: any) => {
        setIsLoading(true);        
        if (payload.roleIds && !isArray(payload.roleIds)) payload.roleIds = [payload.roleIds];
        handleTrimString(payload)
        muUpdateUser.mutate({payload: omit(payload, 'emailAddress'), id: params?.id });
        setIsEdit(false)
    };

        const onSubmitEmail = (payload: any) => {
        setIsLoading(true);        
        if (payload.roleIds && !isArray(payload.roleIds)) payload.roleIds = [payload.roleIds];
        handleTrimString(payload)
        muUpdateUser.mutate({ payload: omit(payload, 'firstName', 'lastName'), id: params?.id });
        setIsEditEmail(false)
    };

    const hookForm = useForm({ mode: 'onChange', resolver: yupResolver(ValidateSchemaFullName) });
    const {
        formState: { errors },
        handleSubmit,
        clearErrors,
        setValue,
        reset
    } = hookForm

    const hookFormEmail =  useForm({mode: 'onChange'})
    const {handleSubmit: handleSubmitEmail, reset: resetEmail} = hookFormEmail
    const name = targetData?.name || `${targetData?.firstName} ${targetData?.lastName}`;
    const handleCloseEdit = () => {
        setIsEdit(false)
        clearErrors()
    }
    const handleCloseEditEmail = () => {
        setIsEditEmail(false)
        clearErrors()
    }


    useEffect(() => {
        if (targetData) {
            reset(pick(targetData, ['firstName', 'lastName']));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targetData.firstName, targetData.lastName, isEdit]);

    useEffect(() => {
  if (targetData) {
            resetEmail(pick(targetData, ['emailAddress']))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targetData?.emailAddress, isEditEmail])

    if (isLoading) {
        return <SpinnerComponent />;
    }

    return (
        <div className="update-patient">
            {!isEdit ? (
                <div className="name flex flex-row items-center mb-3.5">
                    <div>
                        <span className="text-3xl font-semibold text-on-primary-container pb-1 title mr-4 break-word text-header">{name} </span>
                    </div>
                    <div className=''>
                        <Button onClick={() => setIsEdit(true)} className={`h-[28px] w-[28px] flex justify-center bg-white rounded-sm`} color="gray-100">
                            <HiOutlinePencil className="w-4 h-4 text-black" />
                        </Button>
                    </div>
                </div>
            ) : (
                <form onSubmit={handleSubmit(onSubmitName)} >
                    <div className='form input-name flex w-1/2'>
                        <InputText name="firstName" className="md:mr-2" hookForm={hookForm} isHolderLabel={false} />
                        <InputText name="lastName" className="md:mr-4 " hookForm={hookForm} isHolderLabel={false} />
                        <div className="flex my-1 md:my-0">
                            <button
                                type="submit"
                                className={`mr-4 h-34 flex items-center justify-center text-white bg-primary border border-primary focus:outline-none font-medium text-sm px-5 py-2.5 text-center`}
                            >
                                {t('Save')}
                            </button>
                            <button
                                type='button'
                                onClick={() => handleCloseEdit()}
                                className={`text-primary flex h-34 items-center justify-center hover:bg-blue-100 border border-primary focus:outline-none font-medium text-sm px-5 py-2.5 text-center`}
                            >
                                {t('cancel')}
                            </button>
                        </div>
                    </div>
                </form>
            )}

            <div className="text-gray-800 flex items-center">
                <div className="mr-4 py-1 w-20">{t('Email')}</div>
                {!isEditEmail ? (
                    <div className="flex flex-row">
                        <div className="mr-4">{targetData?.emailAddress}</div>
                        <div className=''>
                            <Button onClick={() => setIsEditEmail(true)} className={`h-[28px] w-[28px] flex justify-center bg-white rounded-sm`} color="gray-100">
                                <HiOutlinePencil className="w-4 h-4 text-black" />
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className="grow pt-2">
                    <form onSubmit={handleSubmitEmail(onSubmitEmail)} >
                        <div className='flex form w-1/2'>
                            <InputText name="emailAddress" className="mr-4 w-1/2" hookForm={hookFormEmail} isHolderLabel={false} />
                            <div className="flex my-1 md:my-0">
                                <button
                                    type="submit"
                                    className={`mr-4 h-34 flex items-center justify-center text-white bg-primary border border-primary focus:outline-none font-medium text-sm px-5 py-2.5 text-center`}
                                >
                                    {t('Save')}
                                </button>
                                <button
                                    type='button'
                                    onClick={() => handleCloseEditEmail()}
                                    className={`text-primary flex h-34 items-center justify-center hover:bg-blue-100 border border-primary focus:outline-none font-medium text-sm px-5 py-2.5 text-center`}
                                >
                                    {t('cancel')}
                                </button>
                            </div>
                        </div>
                    </form>
                    </div>

                )}
            </div>



        </div>

    )
}
export default UpdatePatient