
import PaginateTable from 'components/table/paginate';
import { HiOutlinePencil, HiOutlineTrash, HiOutlineFolder } from 'react-icons/hi';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { apiStatus, pageCount, permissionKeys, routePaths } from 'utils/constants';
import { isEmpty } from 'lodash';
import NoResult from 'components/commonComponent/noResult';
import { useSelector } from 'react-redux';
import ActionTable from 'components/table/actionTable';
import { orgSelector, userSelector } from 'redux/selectors';
import Table from 'components/table/table';
import { useLocation, useNavigate } from 'react-router-dom';
import Actions from 'components/actions';
import SpinnerComponent from 'components/spinner';
import { deleteProgramTemplate, getProgramTemplates } from 'api/programTemplateApi';
import moment from 'moment';
import ListEmpty from 'components/empty/ListEmpty';
import { toast } from 'react-toastify';
import { IconContext } from 'react-icons'
import AddProgramByNewBlankCardModal from '../patient/addProgramByNewBlankCardModal';

const ProgramTemplateList = (props: any) => {
    const queryClient = useQueryClient();
    const { WRITE_USER } = permissionKeys;
    const [t] = useTranslation();
    const navigate = useNavigate();
    const { organizationId } = useSelector(orgSelector);
    const { userInfo } = useSelector(userSelector);
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [totalEntities, setTotalEntities] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [programTemplates, setProgramTemplates] = useState([]);
        const [openNewBlankCardModal, setOpenNewBlankCardModal] = useState(false)

    const location = useLocation()
    const handleAddProgramTemplate = () => {
        setOpenNewBlankCardModal(true)
    };

    const { data, isLoading, isFetching } = useQuery(
        ['getProgramTemplates', currentPage, searchValue, organizationId],
        () =>
            getProgramTemplates({
                page: currentPage,
                limit: pageCount,
                searchQuery: searchValue,
            }),
        {
            onSuccess: (data: any) => {
                setTotalEntities(data.totalEntities);
            },
            onError: () => setProgramTemplates([]),
            staleTime: Infinity,
        },
    );

    const handleSearch = (value: string) => {
        setSearchValue(value);
        setCurrentPage(0);
    };

    useEffect(() => {
        if (data !== undefined) {
            setProgramTemplates(data?.data?.entities);
            setTotalEntities(data?.data.totalEntities);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        setCurrentPage(0)
    }, [organizationId])

    if (!isLoading && isEmpty(searchValue) && totalEntities === 0) {
        return (
            <div className="w-full sm:p-8 p-4">
                <ListEmpty
                    icon={
                        <IconContext.Provider value={{ color: "#D1D5DB", style: { strokeWidth: 1.5 } }}>
                            <HiOutlineFolder name='program-template-empty' className='h-[84px] w-auto' />
                        </IconContext.Provider>
                    }
                    buttonName={t('addTemplate')}
                    handleAddClick={handleAddProgramTemplate}
                    note={t('noteProgramTemplateEmpty')}
                    title={t('titleProgramTemplateEmpty')}
                />
            </div>
        );
    }

    const handleEditProgramTemplate = (programTemplateId: string) => {
          navigate(`${location.pathname}/program-template-detail?programTemplateId=${programTemplateId}`)
        
    }

    const handleRemoveProgramTemplate = async (programId: string) => {
        try {
            const data = await deleteProgramTemplate(programId);
            if (data.status === apiStatus.SUCCESS) {
                const message: string = t('deletedProgramTemplate');
                toast.success(message);
                queryClient.invalidateQueries('getProgramTemplates', { refetchActive: true }, { cancelRefetch: true });
            }
        } catch (e) {
            const message: string = t('generalErrors');
            toast.error(message);
        }
    }

    return (
        <div className="w-full h-full sm:px-8 px-4">
            <div className='md:py-5'>
                <ActionTable
                    placeholderSearch={t('Search')}
                    buttonName={t('addTemplate')}
                    handleAddClick={handleAddProgramTemplate}
                    handleSearch={handleSearch}
                />
            </div>
            {isLoading && <SpinnerComponent />}
            {totalEntities === 0 && !isLoading && <NoResult />}
            {totalEntities > 0 && (
                <>
                    <div className="overflow-x-auto mb-2 list-table">
                        <Table>
                            <Table.Head>
                                <Table.HeadCell className="cursor-pointer">
                                    <div className="flex items-center">{t('programTemplateName')}</div>
                                </Table.HeadCell>
                                <Table.HeadCell className="hidden-mobile-tablet">
                                    <div className="flex items-center">{t('lastUpdatedDate')}</div>
                                </Table.HeadCell>
                                <Table.HeadCell scope="col" className="py-2 w-6" />
                            </Table.Head>
                            <Table.Body>
                                {programTemplates.map((item: any) => (
                                    <Table.Row key={`row-${item.id}`} className="bg-white text-base hover:border-b text-on-primary-container">
                                        <Table.Cell className="text-sm py-2.5 " onClick={() => handleEditProgramTemplate(item?.id)}>
                                            {item.name}
                                        </Table.Cell>
                                        <Table.Cell className="text-sm hidden-mobile-tablet py-2.5">
                                            {item.modified ? moment(item?.modified).format('MMMM DD, YYYY') : ''}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Actions>
                                                <Actions.Item
                                                    show
                                                    action={() => handleEditProgramTemplate(item?.id)}
                                                    icon={<HiOutlinePencil className="w-5 h-5  text-on-primary-container" strokeWidth={2.5} />}
                                                />
                                                <Actions.Item
                                                    show
                                                    action={() => handleRemoveProgramTemplate(item?.id)}
                                                    icon={<HiOutlineTrash className="w-5 h-5  text-on-primary-container" strokeWidth={2.5} />}
                                                />
                                            </Actions>
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                            {isLoading && <SpinnerComponent />}
                        </Table>
                    </div>
                    <PaginateTable setCurrentPage={setCurrentPage} currentPage={currentPage} totalEntities={totalEntities} />
                </>
            )}
            {openNewBlankCardModal && (
                    <AddProgramByNewBlankCardModal
                        isTemplate
                        headerTitle={t('newBlankProgram')}
                        openBlankCardModal={openNewBlankCardModal}
                        setOpenBlankCardModal={setOpenNewBlankCardModal}
                        setOpenModal={setOpenModal}
                    />
                )}
        </div>
    );
};

export default ProgramTemplateList;
