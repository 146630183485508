// eslint-disable-next-line
import { queryFilter } from 'utils/proptypes';
import base from './baseApi';
import { omitBy } from 'lodash';
import { createSearchParams } from 'react-router-dom';

const { urls, methods, execute } = base();
const getOrganizationByFilter = (queryFilter: queryFilter) => {
  const method = methods.GET;
  const initParams: any = omitBy(queryFilter, v => v === '');
  const params = createSearchParams(initParams);

  const url = `${urls.organization.filter}/?${params.toString()}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const getOrganizations = () => {
  const method = methods.GET;
  const url = `${urls.organization.organizations}?limit=0`;
  const response = execute(method, url);

  return response;
};

const getOrganizationById = (id: string) => {
  const method = methods.GET;
  const url = `${urls.organization.organizations}/${id}`;
  const response = execute(method, url);

  return response;
};

const createOrganization = (payload: any) => {
  const method = methods.POST;
  const url = `${urls.organization.organizations}`;
  const response = execute(method, url, payload);

  return response;
};

const updateOrganizationById = (payload: { id: any, name: string, address: string, phone: string, stripeSubscriptionExpireDate: any }) => {
  const method = methods.PUT;
  const url = `${urls.organization.organizations}/${payload.id}`;
  const response = execute(method, url, { name: payload.name, address: payload.address, phone: payload.phone,stripeSubscriptionExpireDate: payload.stripeSubscriptionExpireDate });
  return response;
};

const updateStatusOrganizationById = (payload: { id: any, isActive: boolean }) => {
  const method = methods.PATCH;
  const url = `${urls.organization.organizations}/${payload.id}`;
  const response = execute(method, url, { isActive: payload.isActive });

  return response;
};

const updateOrganizationImage = (payload: { id: string, file?: any }) => {
  const { id, file } = payload;
  const method = methods.POST;
  const url = `${urls.organization.organizations}/${id}/uploadimage`;
  const response = execute(method, url, file);
  return response;
};

const setOrgCookie = (id: any) => {
  const method = methods.GET;
  const url = `${urls.organization.organizations}/${id}/set-context`;
  const response = execute(method, url);

  return response;
};

export {
  getOrganizationByFilter,
  getOrganizationById,
  updateOrganizationById,
  getOrganizations,
  createOrganization,
  updateStatusOrganizationById,
  setOrgCookie,
  updateOrganizationImage,
};
