import useUser from '../../hooks/useUser';
import { useEffect } from 'react';
import { Spinner } from 'flowbite-react';

const LogoutPage = () => {
  const { handleLogout } = useUser();
  useEffect(() => {
    handleLogout().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="fixed w-full h-full bg-tertiaryDark top-0 left-0 z-50">
    <span className="spinner">
      <Spinner aria-label="Center-aligned spinner example" />
    </span>
    </div>
  );;
};

export default LogoutPage;
