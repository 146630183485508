/* eslint-disable react/jsx-props-no-spreading */

import Button from "components/button/button";
import ButtonBack from "components/button/buttonBack"
import InputText from "components/inputText";
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useFieldArray, useForm } from 'react-hook-form';
import Select from 'components/select';
import { ProgramStatus, ProgramStatusOptions, ProgramType, ProgramTypeOptions, routePaths, status } from "utils/constants";
import { useEffect, useRef, useState } from "react";
import ExerciseItem from "./exerciseItem";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { getSecondsFromHHMMSS, messageErrors, toHHMMSS } from "utils/utils";
import { changeProgramStatus, createProgram, getProgramById, updateProgram } from "api/programApi";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { HiOutlinePlus, HiOutlinePrinter, HiOutlinePencil } from 'react-icons/hi';
import './programDetail.scss'
import { omitBy, omit, mapValues, isEmpty } from "lodash";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { copyFile } from "api/fileApi";
import SpinnerComponent from "components/spinner";
import { createProgramTemplate, getProgramTemplateById, updateProgramTemplate } from "api/programTemplateApi";
import { uploadFile } from "api/exerciseApi";
import ProgramPDF from "./pdf/programPDF";
import { useReactToPrint } from "react-to-print";
import { duration } from "moment";
import DiscardSaveModal from '../../../components/modal/discardSaveModal';
import ExerciseRow from "./exerciseRow";
import EditExerciseModal from "./editExerciseModal";
import Empty from "components/empty";
import Svg from "components/svg";
import ListEmpty from "components/empty/ListEmpty";


const ProgramDetail = (props: any) => {
    const { isTemplate = false, isPatientSite = false } = props
    const initExercise = {
        name: '',
        videoUrl: '',
        fileId: null,
        description: '',
        sets: null,
        reps: null,
        duration: '',
        timesPerDay: 1,
        order: null,
        isSunday: false,
        isMonday: false,
        isTuesday: false,
        isWednesday: false,
        isThursday: false,
        isFriday: false,
        isSaturday: false,
        isArchived: false,
    }
    const [t] = useTranslation()
    const [selectedType, setSelectedType] = useState<any>(null)
    const [selectedStatus, setSelectedStatus] = useState<any>(null)
    const [isUploadingFile, setIsUploadingFile] = useState(false)
    const [fileErrors, setFileErrors] = useState<any>({})
    const [selectedProgram, setSelectedProgram] = useState<any>(null)
    const [open, setOpen] = useState({})
    const [isLoading, setIsLoading] = useState(false);
    const [focusExercise, setFocusExercise] = useState<any>(null);
    const [openDiscardSaveModal, setOpenDiscardSaveModal] = useState(false)
    const params = useParams()
    const [searchParams] = useSearchParams();
    const pdfRef: any = useRef();
    const endOfPageRef: any = useRef(null);
    const [isEditName, setIsEditName] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [selectedExercise, setSelectedExercise] = useState(null)
    const queryClient = useQueryClient();
    const navigate = useNavigate()

    console.log("params", params);

    const programId: any = isPatientSite ? params?.programId : searchParams.get('programId') || null
    const programTemplateId: any = searchParams.get('programTemplateId') || null
    const [expands, setExpands]: any = useState([]);
    const [expandsArchived, setExpandsArchived]: any = useState([]);
    const [expandAll, setExpandAll]: any = useState([]);
    const [expandAllArchived, setExpandAllArchived]: any = useState([]);
    const [isExpandAll, setIsExpandAll]: any = useState(false);
    const [archivedExercises, setArchivedExercise] = useState([]);
    const [unarchivedExercises, setUnarchivedExercise] = useState([]);
    const { data: program, isLoading: isLoadingProgram } = useQuery(
        ['getProgramById', programId],
        () =>
            getProgramById(programId),
        {

            onError: (error) => {
                const message: string = messageErrors(error, t);
                toast.error(message);
            },
            enabled: programId !== null,
            staleTime: Infinity,
        },
    );

    console.log("programTemolateID", programTemplateId);

    const { data: programTemplate, isLoading: isLoadingProgramTemplate } = useQuery(
        ['getProgramTemplateById', programTemplateId],
        () =>
            getProgramTemplateById(programTemplateId),
        {

            onError: (error) => {
                const message: string = messageErrors(error, t);
                toast.error(message);
            },
            enabled: programTemplateId !== null,
            staleTime: Infinity,
        },
    );
    const ValidateSchema = Yup.object().shape({
        name: Yup.string().required(t('userManagementPage.requiredField')),
        status: !isTemplate ? Yup.string().required(t('userManagementPage.requiredField')) : Yup.string().nullable(),
        exercises: Yup.array()
            .of(
                Yup.object().shape({
                    name: Yup.string()
                        .required(t('userManagementPage.requiredField')),
                    timesPerDay: Yup.number()
                        .required(t('userManagementPage.requiredField')),
                })
            )
    });


    const [exerciseErrors, setExerciseErrors] = useState({})

    const hookForm = useForm({
        mode: "onChange",
        resolver: yupResolver(ValidateSchema),
        defaultValues: {
            exercises: [initExercise]
        }
    });


    const {
        formState: { errors, isDirty },
        handleSubmit,
        reset,
        getValues,
        setValue,
        setError,
        register,
        control,
        clearErrors
    } = hookForm

    const { fields, remove, append, move, insert, update } = useFieldArray<any>({
        control,
        name: "exercises",
    });
    const handleMutationProgram = (payload: any) => {
        return !!programId ? updateProgram(programId, payload) : createProgram(payload)
    }

    const mutationCreateProgram = useMutation('createProgram', {
        mutationFn: handleMutationProgram,
        retry: false,
        onSuccess: () => {
            const message: string = !!programId ? t('updatedProgram') : t('createdProgram');
            toast.success(message);
            hookForm.reset()
            setSelectedStatus(null)
            setSelectedType(null)
            setOpenEditModal(false)
            queryClient.invalidateQueries('getPatientPrograms', { refetchActive: true }, { cancelRefetch: true });
            queryClient.invalidateQueries('getProgramById', { refetchActive: true }, { cancelRefetch: true });
            queryClient.invalidateQueries('getAllPatientPrograms', { refetchActive: true }, { cancelRefetch: true });

            // navigate(`${routePaths.THERAPIST_PATIENTS}/${params.id}`, { state: { tab: 'program' } });
            setIsLoading(false);
        },
        onError: error => {
            const message: string = messageErrors(error, t);
            toast.error(message);
            setIsLoading(false);
        },
    });

    const handleMutationProgramTemplate = (payload: any) => {
        return !!programTemplateId ? updateProgramTemplate(programTemplateId, payload) : createProgramTemplate(payload)
    }
    const mutationCreateProgramTemplate = useMutation('createProgramTemplate', {
        mutationFn: handleMutationProgramTemplate,
        onSuccess: () => {
            const message: string = !!programTemplateId ? t('updatedProgramTemplate') : t('createdProgramTemplate');
            toast.success(message);
            hookForm.reset()
            setSelectedStatus(null)
            setSelectedType(null)
            setOpenEditModal(false)
            queryClient.invalidateQueries('getProgramTemplates', { refetchActive: true }, { cancelRefetch: true });
            queryClient.invalidateQueries('getProgramByTemplateId', { refetchActive: true }, { cancelRefetch: true });
            queryClient.invalidateQueries('getAllPatientPrograms', { refetchActive: true }, { cancelRefetch: true });
            queryClient.invalidateQueries('getProgramTemplateById', { refetchActive: true }, { cancelRefetch: true });

            // navigate(routePaths.THERAPIST_PROGRAM_TEMPLATES);
            setIsLoading(false);

        },
        onError: error => {
            const message: string = messageErrors(error, t);
            toast.error(message);
            setIsLoading(false);
        },
    });

    const handleUploadExerciseFile = async (exercises: any) => {
        let fileIds: any = []
        for (let i = 0; i < exercises.length; i++) {
            // If the exercise is copied from another exercise, do not upload the file; just copy it in the database.
            if ((exercises[i].isDuplicate || exercises[i].isTemplate) && !exercises[i].file?.name && !!exercises[i]?.file?.id) {
                setIsUploadingFile(true)
                const { data } = await copyFile(exercises[i].file);
                setIsUploadingFile(false)

                fileIds.push({ ...omit(exercises[i], 'file'), order: i, fileId: data.id })
            }
            // If the exercise is new
            else {
                if (exercises[i].file?.name) {
                    const currentFileName = exercises[i].file.name
                        .split('.')
                        .map((item: string) => item.toLowerCase())
                        .join('.');
                    const formData = new FormData();
                    formData.append('file', exercises[i].file, currentFileName);
                    setIsUploadingFile(true)
                    const { data } = await uploadFile({ file: formData });
                    setIsUploadingFile(false)

                    if (data) {
                        fileIds.push({ ...omit(exercises[i], 'file'), order: i, fileId: data.id })
                    }
                } else {
                    if (exercises[i].fileUrl !== null) {
                        fileIds.push({ ...omit(exercises[i], 'file'), order: i })
                    }
                    else {
                        fileIds.push({ ...omit(exercises[i], 'file'), fileId: null, order: i })
                    }
                }
            }


        };
        return fileIds

    }

    const handleSubmitDrag = async (values: any) => {
        const allExercise = [...values?.exercises, ...archivedExercises]
        const exercises = await handleUploadExerciseFile(allExercise)
        if (exercises) {
            const convertExercises = exercises?.map((item: any, index: any) => (!!programId ? { ...mapValues(item, v => v === '' ? null : v) } : { ...omitBy(item, v => v === '') }))
            const payload = { ...values, exercises: convertExercises, type: ProgramType.TREATMENT, patientId: params.id }
            isTemplate ? mutationCreateProgramTemplate.mutate(payload) : mutationCreateProgram.mutate(payload)
        }
    }

    const onSubmit = async (values: any) => {
        if (isDirty) {
            console.log("set123123123");

            setIsLoading(true);
            try {
                const hasFileError = Object.keys(fileErrors).some(v => fileErrors[v])
                if (!hasFileError) {
                    const exerciseError = values.exercises?.map((ex: any, index: number) => ({ name: ex.name === '' ? true : false }))
                    setExerciseErrors(exerciseError)

                    if (!exerciseError.some((error: any) => error.name)) {
                        const exercises = await handleUploadExerciseFile(values.exercises)

                        if (exercises) {
                            const convertExercises = exercises?.map((item: any, index: any) => (!!programId ? { ...mapValues(item, v => v === '' ? null : v), duration: item.duration ? Math.max(0, getSecondsFromHHMMSS(item.duration)) : null } : { ...omitBy(item, v => v === ''), duration: item.duration ? Math.max(0, getSecondsFromHHMMSS(item.duration)) : null }))
                            const payload = { ...values, exercises: convertExercises, type: ProgramType.TREATMENT, patientId: params.id }
                            isTemplate ? mutationCreateProgramTemplate.mutate(payload) : mutationCreateProgram.mutate(payload)
                        }
                    }
                    setIsEditName(false)
                }
            } catch (error) {
                console.log("error", error);
                setIsUploadingFile(false)
                toast.error(messageErrors(error, t));
            }
        } else {
            handleBackToProgram()
        }

    }

    const reactToPrint = useReactToPrint({ content: () => pdfRef.current });
    const handlePrint = async (item: any) => {
        try {
            const program: any = await getProgramById(programId)
            if (program) {
                setSelectedProgram(program.data)
                setTimeout(() => {
                    reactToPrint();
                }, 200);
            }
        }
        catch (error) {
            console.log("error");
        }
    };

    useEffect(() => {
        if (program?.data) {

            const archivedExercisesTemp = program?.data?.exercises
                ?.filter((item: any) => item.isArchived)
                .map((item: any) => ({ ...mapValues(item, v => (v === null ? '' : v)), duration: item.duration ? toHHMMSS(item.duration) : '' }));
            setArchivedExercise(archivedExercisesTemp);

            const unarchivedExercisesTemp = program?.data?.exercises
                ?.filter((item: any) => !item.isArchived)
            // .map((item: any) => ({ ...item, duration: item.duration ? toHHMMSS(item.duration) : '' }));
            setUnarchivedExercise(unarchivedExercisesTemp);

            const initData = {
                name: program.data.name,
                exercises: unarchivedExercisesTemp?.map((item: any) => ({ ...item, duration: item.duration ? toHHMMSS(item.duration) : '' }))
            };

            reset(initData)
            const type: any = ProgramTypeOptions.filter(item => item.value === program.data.type)
            const status: any = ProgramStatusOptions.filter(item => item.value === program.data.status)
            setSelectedType(type)
            setSelectedStatus(status)
            hookForm.setValue('type', type[0]?.value)
            hookForm.setValue('status', status[0]?.value)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [program, programId])

    useEffect(() => {
        if (programTemplate?.data) {
            const initData = {
                name: programTemplate.data.name,
                exercises: programTemplate.data.exercises?.map((item: any) => ({ ...item, duration: item.duration ? toHHMMSS(item.duration) : '' }))
            };
            reset(initData)
            const type: any = ProgramTypeOptions.filter(item => item.value === programTemplate.data.type)
            setSelectedType(type)
            hookForm.setValue('type', type[0]?.value)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [programTemplate, programTemplateId])

    const handleSelectType = (option: any) => {
        hookForm.clearErrors('type')
        const type: any = [option]
        setSelectedType(type)
        hookForm.setValue('type', type[0]?.value, { shouldDirty: true })
    }

    const handleSelectStatus = (option: any) => {
        hookForm.clearErrors('status')
        const status: any = [option]
        setSelectedStatus(status)
        hookForm.setValue('status', status[0]?.value, { shouldDirty: true })

    }

    const handleDrag = ({ source, destination }: any) => {
        if (destination) {
            move(source.index, destination.index);
            handleSubmit(handleSubmitDrag)();
        }
    };

    const handleBackToProgram = () => {
        setOpenDiscardSaveModal(false);
        if (isTemplate) {
            navigate(routePaths.THERAPIST_PROGRAM_TEMPLATES);
        } else if (isPatientSite) {
            navigate(routePaths.PATIENT_PROGRAM);
        } else
            navigate(`${routePaths.THERAPIST_PATIENTS}/${params.id}`, { state: { tab: 'program' } });
    }

    const
        handleBack = () => {
            if ((programId || programTemplateId) && isDirty) {
                setOpenDiscardSaveModal(true);
            } else {
                handleBackToProgram();
            }
        }

    const handleAddExercise = () => {
        setOpenEditModal(true)
    }


    const changeStatus = (data: any) => {
        return changeProgramStatus(data)
    };

    const mutationUpdateProgramStatus = useMutation('change-status', {
        mutationFn: changeStatus,
        onSuccess: () => {
            const message = t('Change Status successfully')
            toast.success(message);
            queryClient.invalidateQueries('getProgramById', { refetchActive: true }, { cancelRefetch: true });
        },
        onError: async (error: any) => {
            const message: string = `${error.response.data.errors?.[0].detail}`;
            toast.error(message);
        },
    });
    const handleChangeStatus = (status: string) => {
        const payload = { status: status }
        mutationUpdateProgramStatus.mutate({ payload, id: programId });
    }

    const handleCloseEdit = () => {
        setIsEditName(false)
        clearErrors()
    }

    const handleExpandExercise = (index: any) => {
        setExpands({
            ...expands,
            [index]: !expands?.[index],
        });
        setExpandAll({ ...expandAll, [index]: !expandAll?.[index] });
        setFocusExercise(null);
    };

    const handleExpandArchivedExercise = (index: any) => {
        setExpandsArchived({
            ...expandsArchived,
            [index]: !expandsArchived?.[index],
        });
        setExpandAllArchived({ ...expandAllArchived, [index]: !expandAllArchived?.[index] });
        setFocusExercise(null);
    };


    const handleChangeTab = (isExpand: boolean) => {
        if (!isExpand) {
            setExpands([]);
            setExpandsArchived([]);
        }
        setIsExpandAll(isExpand);
    };

    const handleArchiveExercise = async (event: any, exercise: any, exerciseId: any, index: number) => {
        event?.stopPropagation()
        const programData = await getProgramById(programId);

        if (programData) {
            const exercisesInProgram = programData?.data?.exercises;

            const payloadExercises = exercisesInProgram.map((item: any) => {

                if (item.id === exerciseId) {
                    return { ...exercise, isArchived: !item.isArchived, order: 0 };
                } else {
                    return { ...item, order: item.order < exercise?.order ? item.order + 1 : item.order };
                }
            });
            const payload = { patientId: programData?.data?.patientId, name: programData?.data?.name, exercises: payloadExercises };

            mutationCreateProgram.mutate(payload);
            setIsLoading(true);
        }
    }

    useEffect(() => {
        if (!isTemplate) {
            if (isExpandAll) {
                const expandUnarchived = unarchivedExercises.reduce((acc: any, _, index: any) => {
                    acc[index] = true;
                    return acc;
                }, {});
                const expandArchived = archivedExercises.reduce((acc: any, _, index: any) => {
                    acc[index] = true;
                    return acc;
                }, {});
                setExpandAll(expandUnarchived);
                setExpandAllArchived(expandArchived);
                setExpands([]);
                setExpandsArchived([]);
            } else {
                setExpandAll([]);
                setExpandAllArchived([]);
            }
        } else {
            if (isExpandAll) {
                const expandExerciseProgramTemplate = programTemplate.data.exercises.reduce((acc: any, _: any, index: any) => {
                    acc[index] = true;
                    return acc;
                }, {});
                setExpandAll(expandExerciseProgramTemplate);
            } else {
                setExpandAll([]);
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpandAll]);

    const title: any = getValues('name') || ""

    console.log('expands', expands, expandAll, isExpandAll)
    if (isLoading) {
        return <SpinnerComponent />;
    }

    return (
        <>
            {(!isLoadingProgram && !isLoadingProgramTemplate) ? (
                <>
                    <div className="new-blank-program flex overflow-hidden flex-col h-full">
                        <div className="flex py-2 border-b justify-between items-center pl-5 pr-5 header-program">
                            <div className="flex items-start w-2/3 pr-5">
                                <ButtonBack handleBack={handleBack} />
                                {
                                    !isEditName ? (
                                        <>
                                            <p className="text-tertiary text-lg font-bold text-header">{title}</p>
                                            {!isPatientSite && (
                                                <Button onClick={() => setIsEditName(true)} className={`h-[28px] w-[28px] flex justify-center items-center bg-white rounded-sm ml-2`} color="gray-100">
                                                    <HiOutlinePencil className="w-4 h-4 text-black" />
                                                </Button>
                                            )}

                                        </>
                                    ) :
                                        <form onSubmit={handleSubmit(onSubmit)} className="grow" >
                                            <div className='form input-name  flex w-full items-center '>
                                                <InputText name="name" className="mr-2 h-34" hookForm={hookForm} isHolderLabel={false} />
                                                <div className="flex button">
                                                    <button
                                                        type="submit"
                                                        className={`mr-2 h-34 rounded-[6px] flex items-center justify-center text-white bg-primary border border-primary focus:outline-none font-medium text-sm px-5 py-2.5 text-center`}
                                                    >
                                                        {t('Save')}
                                                    </button>
                                                    <button
                                                        type='button'
                                                        onClick={() => handleCloseEdit()}
                                                        className={`text-primary rounded-[6px] flex h-34 items-center justify-center hover:bg-blue-100 border border-primary focus:outline-none font-medium text-sm px-5 py-2.5 text-center`}
                                                    >
                                                        {t('cancel')}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                }
                            </div>
                            {!isTemplate && !isPatientSite && (
                                <div className="active-button flex items-center">
                                    {programId &&
                                        <div className="bg-white hover:bg-tertiary h-34 w-34 mr-2 flex items-center justify-center rounded-[6px]" onClick={handlePrint}><HiOutlinePrinter
                                            className="w-5 h-5  text-black" strokeWidth={2.5} /></div>}

                                    {/* <Button type="submit" className="bg-primary">{t('save')}</Button> */}
                                    <div className="flex bg-white p-1 h-34 rounded-[6px] gap-2">
                                        <div className={`cursor-pointer px-3 rounded-[6px] flex justify-center items-center ${program?.data?.status === ProgramStatus.ACTIVE ? 'bg-light-blue' : 'bg-white'}`} onClick={() => handleChangeStatus(ProgramStatus.ACTIVE)}>
                                            {ProgramStatus.ACTIVE}
                                        </div>
                                        <div className={`cursor-pointer px-3 rounded-[6px] flex justify-center items-center ${program?.data?.status === ProgramStatus.INACTIVE ? 'bg-light-blue' : 'bg-white'}`} onClick={() => handleChangeStatus(ProgramStatus.INACTIVE)}>
                                            {ProgramStatus.INACTIVE}
                                        </div>
                                        <div className={`cursor-pointer px-3 rounded-[6px] flex justify-center items-center ${program?.data?.status === ProgramStatus.DRAFT ? 'bg-light-blue' : 'bg-white'}`} onClick={() => handleChangeStatus(ProgramStatus.DRAFT)}>
                                            {ProgramStatus.DRAFT}
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                        <div className=" flex justify-center py-[10px]">
                            <div className="w-fit flex bg-[#EDEDED] p-[2px]  rounded-[6px] gap-2 border border-[2px] border-[#EDEDED]">
                                <div className={`uppercase cursor-pointer text-sm py-[5px] px-[15px] font-semibold rounded-[6px] ${!isExpandAll ? 'bg-tertiary text-white' : 'bg-[EDEDED] text-tertiary'}`} onClick={() => handleChangeTab(false)}>
                                    {t('Condensed View')}
                                </div>
                                <div className={`uppercase cursor-pointer text-sm py-[5px] px-[15px] font-semibold rounded-[6px] ${isExpandAll ? 'bg-tertiary text-white' : 'bg-[EDEDED] text-tertiary'}`} onClick={() => handleChangeTab(true)}>
                                    {t('Expanded View')}
                                </div>
                            </div>
                        </div>
                        <div className="py-0 px-0 md:px-[20%] exercise-card overflow-y-auto flex justify-center items-center">
                            <div className="w-2/3  mx-auto grow ">
                                <DragDropContext onDragEnd={handleDrag}>
                                    <ul>
                                        <Droppable droppableId="test-items">
                                            {(provided, snapshot) => (
                                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                                    {fields.map((exer, index) => {
                                                        return (
                                                            <Draggable
                                                                key={`test[${index}]`}
                                                                draggableId={`item-${index}`}
                                                                index={index}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <li
                                                                        key={exer.id}
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                    >
                                                                        <ExerciseRow
                                                                            isTemplate={isTemplate}
                                                                            dragHandleProps={!isPatientSite ? provided.dragHandleProps : null}
                                                                            exercise={exer}
                                                                            hookForm={hookForm}
                                                                            index={index}
                                                                            handleArchiveExercise={handleArchiveExercise}
                                                                            expand={expands[index]}
                                                                            expandAll={expandAll[index]}
                                                                            isExpandAll={isExpandAll}
                                                                            onExpandExercise={handleExpandExercise}
                                                                            setOpenEditModal={setOpenEditModal}
                                                                            setSelectedExercise={setSelectedExercise}
                                                                            queryClient={queryClient}
                                                                            isPatientSite={isPatientSite}
                                                                        />
                                                                    </li>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    })}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </ul>
                                </DragDropContext>
                                <div ref={endOfPageRef} />
                            </div>
                        </div>
                        {
                            archivedExercises.length > 0 && !isPatientSite && (
                                <div className=" py-0 px-[20%] exercise-card flex flex-col">
                                    <div className="py-2 text-tertiary text-sm font-bold">{t('ARCHIVED')}</div>
                                    <div className="w-full  exercise ">

                                        {archivedExercises.map((exer: any, index) => {
                                            return (
                                                <ExerciseRow
                                                    isArchived
                                                    exercise={exer}
                                                    hookForm={hookForm}
                                                    index={index}
                                                    handleArchiveExercise={handleArchiveExercise}
                                                    expand={expandsArchived[index]}
                                                    expandAll={expandAllArchived[index]}
                                                    isExpandAll={isExpandAll}
                                                    onExpandExercise={handleExpandArchivedExercise}
                                                    setOpenEditModal={setOpenEditModal}
                                                    setSelectedExercise={setSelectedExercise}
                                                    queryClient={queryClient}
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        }
                        {(program?.data?.exercises?.length === 0 || programTemplate?.data?.exercises?.length === 0) &&
                            (
                                <div className="w-full px-[20%] py-2">
                                    <ListEmpty
                                        buttonName={t('organizationTherapists.assignPatient')}
                                        title={t('To made exercises for program')}
                                        note={t('Please click + button below')}
                                        icon={<Svg name='exercise-template-empty' className='h-[84px] w-auto' />}
                                    />
                                </div>
                            )}
                        {!isPatientSite && (
                            <div className="pr-10 pb-5 flex justify-end">
                                <div className={` w-14 h-14 rounded-full flex justify-center items-center  bg-orange-500 fixed bottom-8 right-8`}
                                    onClick={handleAddExercise}
                                >
                                    <HiOutlinePlus className="h-8 w-8" color="white" />
                                </div>
                            </div>
                        )}
                    </div>
                    {(mutationCreateProgram.isLoading || isUploadingFile || mutationCreateProgramTemplate.isLoading) && <SpinnerComponent />}
                    <ProgramPDF pdfRef={pdfRef} program={selectedProgram} />
                    {openDiscardSaveModal && <DiscardSaveModal openModal={openDiscardSaveModal} setOpenModal={setOpenDiscardSaveModal} onDiscard={handleBackToProgram} onSave={handleSubmit(onSubmit)} />}
                    {openEditModal && <EditExerciseModal isTemplate={isTemplate} program={isTemplate ? programTemplate : program} mutationCreateProgram={mutationCreateProgram} mutationCreateProgramTemplate={mutationCreateProgramTemplate} openModal={openEditModal} setOpenModal={setOpenEditModal} selectedExercise={selectedExercise} setSelectedExercise={setSelectedExercise} queryClient={queryClient} />}
                </>
            ) : <SpinnerComponent />}
        </>
    )
}
export default ProgramDetail;