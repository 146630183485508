/* eslint-disable react/jsx-props-no-spreading */
import { Badge, TextInput, Button, Tabs, Table } from 'flowbite-react';
import './userDetail.scss';
import { useTranslation } from 'react-i18next';
import {
  HiOutlineClipboard,
  HiOutlineMail,
  HiOutlineCalendar,
  HiOutlinePencil,
  HiOutlineOfficeBuilding,
  HiOutlineX,
  HiPaperAirplane,
  HiOutlineTrash,
  HiOutlineBadgeCheck,
  HiOutlineDuplicate,
} from 'react-icons/hi';
import moment from 'moment';
import OrganizationItem from 'components/organizationItem';
import { useLocation, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useEffect, useState } from 'react';
import { checkPermission, copyToClipboard } from 'utils/utils';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { updateSiteUser, updateOrganizationUser, updateUserImage, getUserById } from 'api/userApi';
import { concat, filter, find, flatten, flattenDeep, isEmpty, map, toLower, uniq } from 'lodash';
import { toast } from 'react-toastify';
import { acceptImageTypeFile, IMAGE_TYPE_REGEX, pageType, permissionKeys } from 'utils/constants';
import { useSelector } from 'react-redux';
import { orgSelector, userSelector } from 'redux/selectors';
import useUser from 'hooks/useUser';
import Actions from 'components/actions';
import ActionTable from 'components/table/actionTable';
import { themeTabs } from 'utils/theme';
import AssignRolesModal from 'components/modal/assignRolesModal';
import NoResult from 'components/commonComponent/noResult';
import Avatar from 'components/GroupAvatar/Avatar';

const UserDetail = (props: any) => {
  const { type } = props;
  const { WRITE_USER, PLATFORM_ADMIN } = permissionKeys;
  const location = useLocation();
  const queryClient = useQueryClient();
  const params: any = useParams();
  const { userInfo } = useSelector(userSelector);
  const { organizationId } = useSelector(orgSelector);
  const { fetchCurrentUser } = useUser();
  const [openModal, setOpenModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [user, setUser] = useState<any>(null);
  const [roles, setRoles] = useState<any>([]);
  const [file, setFile] = useState<any>();
  const [t] = useTranslation();
  const [enableEdit, setEnableEdit] = useState(false);
  const { data, refetch } = useQuery(['getUserDetail', params?.id], () => getUserById(params.id), { staleTime: Infinity });
  const platformAdmin = find(uniq(flattenDeep(map(roles, (r: any) => r?.permissions))), (per: any) => per === PLATFORM_ADMIN);
  const isWriteOrg = checkPermission(userInfo, props.type, [WRITE_USER], organizationId, platformAdmin);
  const [tab, setTab] = useState(location?.state?.tab || 'detail');

  const getRoles = (user: any) => {
    const siteRoles = user?.roles;
    const orgRoles = map(user?.organizations?.filter((o: any) => o.id === organizationId && !!o.role), 'role');
    return type === pageType.SITE ? siteRoles : orgRoles;
  };

  const ValidateSchema = Yup.object().shape({
    firstName: Yup.string().max(100, t('userManagementPage.nameLengthMax')).nullable().required(t('userManagementPage.requiredField')),
    lastName: Yup.string().max(100, t('userManagementPage.nameLengthMax')).nullable().required(t('userManagementPage.requiredField')),
  });

  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
  }: any = useForm({
    mode: 'onChange',
    resolver: yupResolver(ValidateSchema),
  });

  const handleSuccess = (message: string) => {
    toast.success(message);
    refetch();
    queryClient.invalidateQueries('getUsers');
    queryClient.invalidateQueries('getOrganizationDetail');
    queryClient.invalidateQueries('getOrganizations');
    if (params?.id === userInfo.id) fetchCurrentUser();
  };

  const handleSubmitUser = (data: any) => {
    return type === pageType.SITE ? updateSiteUser(data) : updateOrganizationUser(data);
  };
  const muCreateUser = useMutation('create-update-usesOrg', {
    mutationFn: handleSubmitUser,
    onSuccess: () => {
      handleSuccess(t('userManagementPage.editSuccessMessage'))
      reset({});
      setEnableEdit(false);
    },
    onError: async (error: any) => {
      const message: string = `${error.response.data.errors?.[0].detail}`;
      toast.error(message);
    },
  });

  useEffect(() => {
    if (data !== undefined) {
      setUser(data?.data);
      setRoles(getRoles(data?.data)?.filter((role: any) => role?.displayName.includes(searchValue)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (user) {
      const initData = {
        firstName: user.firstName,
        lastName: user.lastName,
      };
      reset(initData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (file?.name) {
      const currentFileName = toLower(file.name);
      const formData = new FormData();
      formData.append('file', file, currentFileName);
      handleUpdateAvatar(formData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const handleSearch = (value: any) => {
    const newRoles = user.roles.filter((role: any) => toLower(role?.displayName).includes(toLower(value)));
    setRoles(newRoles);
    setSearchValue(value);
  };

  const handleAssignRoles = (rolesSelect: any) => {
    const selectRoleIds = map(flatten([rolesSelect]), 'value');
    const roleIds = type === pageType.SITE ? concat(map(user.roles, 'id'), selectRoleIds) : selectRoleIds;
    setOpenModal(false);
    return muCreateUser.mutate({ id: user.id, payload: { roleIds } });
  };

  const removeAssignRoles = (role: any) => {
    const roleIds = map(filter(user.roles, (r: any) => r.id !== role?.id), 'id');
    return muCreateUser.mutate({ id: user.id, payload: { roleIds } });
  };

  const onSubmit = (value: any) => {
    const payload = {
      ...value,
      firstName: isEmpty(value.firstName) ? null : value.firstName,
      lastName: isEmpty(value.lastName) ? null : value.lastName,
    };
    muCreateUser.mutate({ payload, id: params?.id });
  };

  const handleUploadImage = async (e: any) => {
    const currentFile = e.target.files[0];

    if (currentFile) {
      const currentFileName = currentFile.name
        ?.split('.')
        .map((item: string) => item.toLowerCase())
        .join('.');
      if (!IMAGE_TYPE_REGEX.test(currentFileName) && currentFile.size > 8000000) {
        const message: string = t('userManagementPage.errorSizeImage');
        toast.error(message);
        return;
      } else {
        if (!IMAGE_TYPE_REGEX.test(currentFileName)) {
          const message: string = t('userManagementPage.errorTypeImage');
          toast.error(message);
          return;
        }
        if (currentFile.size > 8000000) {
          const message: string = t('userManagementPage.errorSizeImage');
          toast.error(message);
          return;
        }
      }
      currentFile.isUploaded = true;
      setFile(currentFile);
    }
  };

  const handleUpdateAvatar = async (formData: any) => {
    const { data } = await updateUserImage({ id: params?.id, file: formData });
    if (data) handleSuccess(t('userManagementPage.editImageSuccessMessage'))
  };

  const handleCloseForm = () => {
    setEnableEdit(false);
    reset();
  };

  useEffect(() => {
    if (location.state) {
      setTab(location.state?.tab);
      window.history.replaceState({}, document.title);
    }
    // eslint-disable-next-line
  }, [location.key]);

  return (
    <div className="flex flex-col user-detail-container w-full">
      {user && (
        <>
          <div className="user-summary w-full p-6 flex">
            <div className="mr-6 flex items-center user-avatar-container relative">
              <Avatar item={user} className="user-avatar h-32 w-32 text-5xl" />
              {isWriteOrg && (
                <div className="user-avatar-edit flex absolute hidden">
                  <label htmlFor="dropzone-file-user-detail">
                    <HiOutlinePencil className="w-10 h-10 text-white z-2 edit-icon" strokeWidth={1.5} />
                    <input id="dropzone-file-user-detail" type="file" accept={acceptImageTypeFile} onChange={e => handleUploadImage(e)} className="hidden" />
                  </label>
                </div>
              )}
            </div>
            <div className="flex flex-col justify-center">
              <div className="flex mb-3 items-center name-status">
                <p className="mr-3 text-3xl	font-semibold ">{`${user.firstName} ${user.lastName}`}</p>
                {user?.isActive ? (
                  <Badge color="success" className="badge-active px-2.5">
                    {t('active')}
                  </Badge>
                ) : (
                  <Badge className="badge-inactive px-2.5">{t('inactive')}</Badge>
                )}
              </div>
              <div className="flex user-id">
                <p className="mr-2">{t('User ID')}</p>
                <p className="bg-gray-100 px-2 rounded">{user?.id}</p>
              </div>
            </div>
          </div>
          <div className="user-infor h-full">
            {/* eslint-disable-next-line  */}
            <Tabs aria-label="Tabs with underline" variant="underline" className="tab-button" theme={themeTabs}>
              <Tabs.Item active={tab === 'detail'} title={t("DETAILS")}>
                <div className="user-detail w-full">
                  <div className="user-detail-infor flex flex-row p-5 justify-between">
                    <div className="flex flex-col user-detail-infor-side">
                      <div className="flex items-center user-detail-infor-row">
                        <div className="flex items-center user-label">
                          <HiOutlineClipboard className="w-5 h-5 mr-2 text-gray-500" />
                          <p className="text-gray-500 "> {t('userManagementPage.fullName')}</p>
                        </div>
                        <div className={`${enableEdit && 'hidden'} flex`}>
                          <p className="text-gray-900 mr-2 flex items-center user-name">{`${user?.firstName} ${user?.lastName}`}</p>
                          {isWriteOrg && (
                            <div
                              data-testid={`test-edit-user`}

                              className="bg-gray-100 flex items-center w-7 h-7 justify-center rounded-sm	cursor-pointer "
                              onClick={() => setEnableEdit(true)}
                            >
                              <HiOutlinePencil className="w-4 h-4 text-gray-900" />
                            </div>
                          )}
                        </div>
                        <div className={`${!enableEdit && 'hidden'} flex edit-full-name`}>
                          <form onSubmit={handleSubmit(onSubmit)} className="w-full flex items-start edit-full-name-form">
                            <div className={`${errors.firstName && 'border-error'} mr-2 input-name`}>
                              <TextInput id="firstName" type="text" placeholder="Karen" {...register('firstName')} className="rounded" />
                              {errors.firstName && (
                                <div className={`text-red-600 text-xs font-normal mt-1 veri-modal height-16`}>{errors?.firstName?.message}</div>
                              )}
                            </div>
                            <div className={`${errors.lastName && 'border-error'} mr-3 input-name`}>
                              <TextInput id="lastName" type="text" placeholder="Nelson" {...register('lastName')} className="rounded" />
                              {errors.lastName && (
                                <div className={`text-red-600 text-xs font-normal mt-1 veri-modal height-16`}>{errors?.lastName?.message}</div>
                              )}
                            </div>
                            <div className="flex ">
                              <Button type="submit" className="bg-primary hover:bg-primary mr-3 save-button rounded w-11">
                                <HiPaperAirplane className="w-5 h-5 rotate-90" />
                              </Button>
                              <Button
                                onClick={handleCloseForm}
                                className="cancel-button w-12 bg-white hover:bg-white rounded border-blue-600 text-blue-600 w-11"
                              >
                                <HiOutlineX className="w-5 h-5 text-blue-600" />
                              </Button>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="flex items-center user-detail-infor-row">
                        <div className="flex items-center user-label">
                          <HiOutlineMail className="w-5 h-5 mr-2 text-gray-500" />
                          <p className="text-gray-500 "> {t('userManagementPage.email')}</p>
                        </div>

                        <p className="text-gray-900 break-all user-value">{user.emailAddress}</p>
                      </div>
                    </div>
                    <div className="flex flex-col user-detail-infor-side">
                      <div className="flex items-center user-detail-infor-row">
                        <div className="flex items-center user-label">
                          <HiOutlineCalendar className="w-5 h-5 mr-2 text-gray-500" />
                          <p className="text-gray-500 flex items-center"> {t('userManagementPage.createdAt')}</p>
                        </div>
                        <p className="text-gray-900 flex items-center">{moment(user.created).format('MMMM Do YYYY, h:mm:ss A')}</p>
                      </div>
                      <div className="flex items-center user-detail-infor-row">
                        <div className="flex items-center user-label">
                          <HiOutlineCalendar className="w-5 h-5 mr-2 text-gray-500" />
                          <p className="text-gray-500 flex items-center"> {t('userManagementPage.lastLogin')}</p>
                        </div>

                        <p className="text-gray-900 flex items-center">
                          {user?.lastLogin && moment(user?.lastLogin).format('MMMM Do YYYY, h:mm:ss A')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Tabs.Item>
              <Tabs.Item active={tab === 'assignRole'} title={type === pageType.SITE ? t("ROLES") : t("ROLE")} className=" h-full">
                <div className="relative h-full">
                  <div className='py-5'>
                    <ActionTable
                      placeholderSearch={t('roleManagementPage.placeholderSearch')}
                      buttonName={t(type === pageType.SITE ? 'assignRoles' : 'assignRole')}
                      handleAddClick={isWriteOrg && (() => setOpenModal(!openModal))}
                      handleSearch={handleSearch}
                      hiddenSearch={type === pageType.ORGANIZATION}
                    />
                  </div>
                  {isEmpty(getRoles(user)) && (
                    <div className="p-8 mx-8 bg-white border border-gray-200 rounded-lg">
                      <div className="mb-4 flex justify-center">
                        <HiOutlineBadgeCheck size={80} strokeWidth={1} color="#D1D5DB" fontWeight={100} />
                      </div>
                      <p className="text-xl font-semibold text-gray-900 text-center mb-1.5 text-header">{t('userDetail.notificationNotRoles')}</p>
                      <p className="text-base font-normal text-gray-900 text-center	">{t('userDetail.noteNotRoles')}</p>
                    </div>
                  )}

                  {!isEmpty(getRoles(user)) && isEmpty(roles) && <NoResult />}
                  {!isEmpty(getRoles(user)) && !isEmpty(roles) && (
                    <div>
                      <Table className="border-b">
                        <Table.Head className="text-sm text-gray-600 font-semibold">
                          <Table.HeadCell className="bg-gray-100 lg:w-96">{t('name')}</Table.HeadCell>
                          <Table.HeadCell className="bg-gray-100 hidden-mobile-tablet">{t('description')}</Table.HeadCell>
                          <Table.HeadCell className="bg-gray-100 w-6">{''}</Table.HeadCell>
                        </Table.Head>
                        <Table.Body>
                          {roles.map((role: any) => (
                            <Table.Row key={role?.id} className="bg-white text-base hover:bg-gray-100 border-b">
                              <Table.Cell className="text-gray-900 font-semibold py-2.5">
                                <div className="flex flex-row items-center">
                                  <div>{`${role?.displayName || ''}`}</div>
                                  <div className="copy-icon pl-3 cursor-pointer">
                                    <HiOutlineDuplicate size={25} onClick={() => copyToClipboard(role?.displayName, t('roleManagementPage.copiedName'))} />
                                  </div>
                                </div>
                              </Table.Cell>
                              <Table.Cell className="text-gray-900 py-2.5 hidden-mobile-tablet whitespace-pre-line">{role?.description}</Table.Cell>
                              <Table.Cell className="py-2.5">
                                {type === pageType.SITE && (
                                  <Actions>
                                    <Actions.Item
                                      show
                                      icon={<HiOutlineTrash className="w-5 h-5" />}
                                      action={() => !muCreateUser.isLoading && removeAssignRoles(role)}
                                    />
                                  </Actions>
                                )}
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                    </div>
                  )}
                </div>
                <AssignRolesModal
                  headerTitle={t(type === pageType.SITE ? 'assignRoles' : 'assignRole')}
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                  queryClient={queryClient}
                  roles={getRoles(user)}
                  type={type}
                  assignRoles={handleAssignRoles}
                />
              </Tabs.Item>
              <Tabs.Item active={tab === 'organizations'} title={t('ORGANIZATIONS')}>
                <div className="user-detail-org p-5">
                  {user?.organizations.length === 0 ? (
                    <div className="h-full pt-2">
                      <div className="mb-4 flex justify-center">
                        <HiOutlineOfficeBuilding className="w-20 h-20 text-gray-200" strokeWidth={1} />
                      </div>
                      <p className="text-xl font-semibold text-center mb-1">{t('userDetail.haveNoOrgNotification')}</p>
                      <p className="text-base font-normal text-center mb-2	">{t('userDetail.note')}</p>
                    </div>
                  ) : (
                    <div>
                      <p className="text-xl font-semibold text-gray-900 mb-5 text-header">{t('organizationPage.organization')}</p>
                      <div className="organization w-full grid grid-cols-4 gap-4 justify-start">
                        {user?.organizations?.map((item: any) => {
                          return <OrganizationItem key={item.id} organization={item} />;
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </Tabs.Item>
            </Tabs>
          </div>
        </>
      )}
    </div>
  );
};

export default UserDetail;
