import {
  HiOutlineOfficeBuilding, HiOutlinePencil, HiOutlineUser,
  HiOutlinePhone,
  HiOutlineLocationMarker,
  HiUsers,
  HiOutlineCalendar,
} from 'react-icons/hi';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getOrganizationById, updateOrganizationById, updateOrganizationImage, updateStatusOrganizationById } from 'api/organizationApi';
import { useTranslation } from 'react-i18next';
import { IMAGE_TYPE_REGEX, acceptImageTypeFile, permissionKeys } from 'utils/constants';
import { toast } from 'react-toastify';
import { checkPermission, messageErrors } from '../../utils/utils';
import { useSelector } from 'react-redux';
import { orgSelector, userSelector } from 'redux/selectors';
import './organizationDetail.scss';
import GroupAvatar from 'components/GroupAvatar/GroupAvatar';
import { Button, TextInput } from 'flowbite-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import GroupButton from 'components/button/groupButton';
import { useLocation } from 'react-router-dom';
import useUser from 'hooks/useUser';
import SpinnerComponent from 'components/spinner';
import { find, flattenDeep, map, uniq } from 'lodash';
import DatePicker from '../../components/datePicker';
import moment from 'moment/moment';
import ButtonBack from '../../components/button/buttonBack';

const intro = require('../../assets/image/jpg/bg-intro.jpg');
const avatarNone = require('../../assets/image/svg/avatar-none.svg').default;
const therapistIcon = require('../../assets/image/svg/therapist-icon.svg').default;
const patientIcon = require('../../assets/image/svg/patient-icon.svg').default;

const OrganizationDetail = (props: any) => {
  const { WRITE_ORG, PLATFORM_ADMIN } = permissionKeys;
  const location = useLocation();
  const [t] = useTranslation();
  const queryClient = useQueryClient();
  const { userInfo } = useSelector(userSelector);
  const { organizationId } = useSelector(orgSelector);
  const [isEdit, setIsEdit] = useState(false);
  const [organization, setOrganization] = useState<any>({});
  const [file, setFile] = useState<any>();
  const [imgUrl, setImgUrl] = useState('');
  const [dateValue, setDateValue] = useState('');
  const { fetchCurrentUser } = useUser();

  const siteAdmin = find(uniq(flattenDeep(map(userInfo?.roles, (r: any) => r?.permissions))), (per: any) =>
    [WRITE_ORG, PLATFORM_ADMIN].includes(per),
  );
  const isWriteOrg = checkPermission(userInfo, props.type, [WRITE_ORG], organizationId);
  const textStatusLabel = organization.isActive ? 'text-red-900' : 'text-green-500';
  const bgStatusLabel = organization.isActive ? 'bg-red-50' : 'bg-green-50';
  const bgStatusButton = organization.isActive ? 'bg-red-700 enabled:hover:bg-red-600' : 'bg-green-500 enabled:hover:bg-green-400';

  const handleSuccess = (message: string) => {
    toast.success(message);
    refetch();
    fetchCurrentUser();
    queryClient.invalidateQueries('getOrganizations');
    queryClient.invalidateQueries('getUserDetail');
  };

  const handleonError = (error: string) => {
    const message: string = messageErrors(error, t);
    toast.error(message);
  };

  const { data, isLoading, refetch } = useQuery(['getOrganizationDetail', organizationId], () => getOrganizationById(organizationId), {
    staleTime: Infinity,
  });

  const updateStatus = useMutation('updateStatusOrganization', {
    mutationFn: updateStatusOrganizationById,
    onSuccess: () => handleSuccess(t('organizationPage.changeStatusSuccess')),
    onError: handleonError,
  });

  const mutationUpdate = useMutation('organizationUpdate', {
    mutationFn: updateOrganizationById,
    onSuccess: () => {
      handleSuccess(t('organizationPage.editSuccess'));
      changeStatusPage();
    },
    onError: handleonError,
  });

  const mutaOrganizationImage = useMutation('organizationImage', {
    mutationFn: updateOrganizationImage,
    onSuccess: () => handleSuccess(t('organizationPage.changeImageSuccess')),
    onError: handleonError,
  });

  const changeStatusPage = () => {
    setIsEdit(!isEdit);
    reset({
      name: data?.data.name,
      address: data?.data.address,
      phone: data?.data.phone,
      stripeSubscriptionExpireDate: data?.data.stripeSubscriptionExpireDate
    });
  };

  const ValidateSchema = Yup.object().shape({
    name: Yup.string().required(t('userManagementPage.requiredField')).max(60, t('organizationModal.maxLength60')),
    address: Yup.string().max(255, t('organizationModal.maxLength255')).nullable(),
    phone: Yup.string().max(100, t('organizationModal.maxLength100')).nullable(),
  });

  const hookForm: any = useForm({
    mode: 'onChange',
    resolver: yupResolver(ValidateSchema),
  });

  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
    setValue,
  } = hookForm;
  const onSubmit = (values: any, props: any) => {
    mutationUpdate.mutate({ id: data?.data?.id, ...values });
  };

  const handleSelectDate = (date: Date) => {
    const formatDate: any = moment(date).format('YYYY-MM-DD');
    setValue('stripeSubscriptionExpireDate', formatDate);
    const tempDate: any = moment(date).format('MMMM DD, YYYY');
    setDateValue(tempDate);
  };

  const handleUploadImage = async (e: any) => {
    const currentFile = e.target.files[0];
    if (currentFile) {
      const currentFileName = currentFile.name
        ?.split('.')
        .map((item: string) => item.toLowerCase())
        .join('.');
      if (!IMAGE_TYPE_REGEX.test(currentFileName) && currentFile.size > 8000000) {
        return;
      } else {
        if (!IMAGE_TYPE_REGEX.test(currentFileName)) {
          return;
        }
        if (currentFile.size > 8000000) {
          return;
        }
      }
      setImgUrl(URL.createObjectURL(currentFile));
      setFile(currentFile);
      currentFile.isUploaded = true;
    }
  };

  const handleCancelEdit = () => {
    setIsEdit(false)
          if (data?.data.stripeSubscriptionExpireDate){
        setDateValue(moment(data?.data?.stripeSubscriptionExpireDate).format('MMMM DD, YYYY'));
    } else setDateValue('')

  }

  useEffect(() => {
    if (data?.data) {
      setImgUrl(data?.data?.image?.url);
      setOrganization(data?.data);
      reset({
        name: data?.data.name,
        address: data?.data.address,
        phone: data?.data.phone,
        stripeSubscriptionExpireDate: data?.data.stripeSubscriptionExpireDate,
      });
      setOrganization(data?.data);
      if (data?.data.stripeSubscriptionExpireDate) setDateValue(moment(data?.data?.stripeSubscriptionExpireDate).utc().format('MMMM DD, YYYY'));
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (file?.name) {
      const currentFileName = file.name
        .split('.')
        .map((item: string) => item.toLowerCase())
        .join('.');
      const formData = new FormData();
      formData.append('file', file, currentFileName);
      mutaOrganizationImage.mutate({ id: organizationId, file: formData });
    }
    // eslint-disable-next-line
  }, [file]);

  useEffect(() => {
    if (location.state) {
      window.history.replaceState({}, document.title);
    }
    // eslint-disable-next-line
  }, [location.key]);

  
  if (isLoading) return <SpinnerComponent />;
  return (
    <div className="org-detail w-full">
      <div className="banner org-top w-full flex items-center relative top-0 left-0 px-8 overflow-hidden">
        <img alt="MileMarker logo" className="absolute left-0 top-0 min-w-full min-h-full max-w-full" src={intro} />
        <div className="pr-2 z-[2] h-full py-4 absolute md:relative">
          <ButtonBack />
        </div>
        <div className="flex flex-col md:flex-row flex-1 items-center justify-center">
        <div
          className="avatar rounded-md h-32 w-32 flex items-center justify-center bg-white relative overflow-hidden">
          {imgUrl ? <img alt="avatar" className="text-base" src={imgUrl} /> :
            <img alt="avatar" className="banner-image" src={avatarNone} />}
          <label
            className="icon opacity-0 bg-opacity-50 bg-gray-900 absolute h-full w-full top-0 right-0 left-0 flex items-center justify-center"
            htmlFor="dropzone-file-organization-detail"
          >
            <HiOutlinePencil className="text-white w-8 h-8" />
            <input id="dropzone-file-organization-detail" type="file" accept={acceptImageTypeFile}
                   onChange={e => handleUploadImage(e)} className="hidden" />
          </label>
          <div className="text-xs absolute bottom-1 z-1 font-normal uppercase text-white">
            {organization?.isActive &&
              <span className="bg-green-400 px-2.5 py-1 rounded">{t('active')}</span>}
            {!organization?.isActive &&
              <span className="bg-red-700 px-2.5 py-1 rounded">{t('inactive')}</span>}
          </div>
        </div>
        <div className="pl-8 flex-1 banner-context-org overflow-hidden pt-2">
          <div className="flex items-center context">
            <div>
              <span
                className="text-3xl font-semibold text-gray-900 title mr-2 break-word text-header">{organization?.name} </span>
            </div>
          </div>
        </div>
        </div>
      </div>
      <div className="px-8 tab-content">
        <div className="box bg-white w-full mt-4 md:mt-8 px-8 py-6 mb-8 border border-gray-200 rounded-lg relative">
          {isWriteOrg && (
            <HiOutlinePencil className="bg-gray-100 h-8 w-8 p-1.5 rounded absolute right-0 top-0 cursor-pointer" onClick={changeStatusPage} />
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="my-1">
              <div className="xl:grid xl:grid-cols-6">
                <div className="row flex col-span-3">
                  <div className="row-item flex mt-2 text-tertiary">
                    <HiOutlineOfficeBuilding className="h-5 w-5 mt-0.5" />
                    <div className="mx-2 w-44">{t('organizationModal.name')}</div>
                  </div>
                  {isEdit && (
                    <div className="row-item flex-1">
                      <TextInput
                        className=""
                        color={errors?.name ? 'failure' : 'gray'}
                        id="name"
                        type="text"
                        placeholder="Jese Leos"
                        /* eslint-disable react/jsx-props-no-spreading */
                        {...register('name')}
                        helperText={<>{errors?.name?.message}</>}
                      />
                    </div>
                  )}
                  {!isEdit &&
                    <div className="row-item left mr-2 flex-1 mt-2 font-semibold break-word">{organization.name}</div>}
                </div>
                <div className="row flex col-span-3">
                  <div className="row-item flex mt-2 text-tertiary xl:ml-12">
                    <HiOutlinePhone className="h-5 w-5 mt-0.5" />
                    <div className="mx-2 w-44">{t('organizationModal.phone')}</div>
                  </div>
                  {isEdit && (
                    <div className="row-item flex-1">
                      <TextInput
                        className="flex-1"
                        color={errors?.phone ? 'failure' : 'gray'}
                        id="phone"
                        type="text"
                        placeholder=""
                        /* eslint-disable react/jsx-props-no-spreading */
                        {...register('phone')}
                        helperText={<>{errors?.phone?.message}</>}
                      />
                    </div>
                  )}
                  {!isEdit &&
                    <div className="row-item left mr-2 flex-1 mt-2 break-word ">{organization?.phone ?? '-'}</div>}
                </div>
              </div>
            </div>
            <div className="my-1">
              <div className="xl:grid xl:grid-cols-6">
                <div className="row flex col-span-3">
                  <div className="row-item flex mt-2 text-tertiary">
                    <HiOutlineLocationMarker className="h-5 w-5 mt-0.5" />
                    <div className="mx-2 w-44">{t('organizationModal.address')}</div>
                  </div>
                  {isEdit && (
                    <div className="row-item flex-1">
                      <TextInput
                        className=""
                        color={errors?.address ? 'failure' : 'gray'}
                        id="address"
                        type="text"
                        placeholder=""
                        /* eslint-disable react/jsx-props-no-spreading */
                        {...register('address')}
                        helperText={<>{errors?.address?.message}</>}
                      />
                    </div>
                  )}
                  {!isEdit &&
                    <div className="row-item left mr-2 flex-1 mt-2 break-word">{organization?.address ?? '-'}</div>}
                </div>
                <div className="row flex col-span-3">
                  <div className="row flex items-center">
                    <div className="row-item right flex items-center text-tertiary xl:ml-12">
                      <HiOutlineUser className="h-5 w-5" />
                      <div className="mx-2 w-44">
                        {organization.countUser} {organization.countUser < 2 ? t('member') : t('members')}
                      </div>
                    </div>
                    <div className="row-item mt-2">
                      <GroupAvatar items={organization?.users} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {siteAdmin && (
           <div className="my-1">
              <div className="xl:grid xl:grid-cols-6 ">
                <div className="row flex col-span-3">
                  <div className="row-item flex mt-2 text-tertiary">
                    <HiOutlineCalendar className="h-5 w-5 mt-0.5" />
                    <div className="mx-2 w-44">{t('organizationModal.stripeSubscriptionExpireDate')}</div>
                  </div>
                  {isEdit && (
                    <div className="row-item flex-1">
                      <DatePicker
                        className=" w-full"
                        hookForm={hookForm}
                        name="stripeSubscriptionExpireDate"
                        dateValue={dateValue}
                        handleSelectDate={handleSelectDate}
                        isHolderLabel={true}
                        {...register('stripeSubscriptionExpireDate')}
                      />
                    </div>
                  )}
                  {!isEdit &&
                    <div className="row-item left mr-2 flex-1 mt-2 break-word">{organization?.stripeSubscriptionExpireDate ? moment(organization?.stripeSubscriptionExpireDate).utc().format('MMMM DD, YYYY') : '-'}</div>}
                </div>
              </div>
            </div>
            )}
            {isEdit && (
              <div className="mt-2 flex">
                <GroupButton
                  className="items-center justify-center pt-2 pb-2"
                  buttons={[
                    {
                      type: 'submit',
                      text: t('saveChanges'),
                      classType: 'blue',
                      isLoading: mutationUpdate.isLoading,
                    },
                    {
                      type: 'button',
                      text: t('modal.cancel'),
                      classType: 'white',
                      action: () => handleCancelEdit(),
                    },
                  ]}
                />
              </div>
            )}
          </form>
        </div>
        <div className="organization box bg-white w-full p-8 mb-8 border border-gray-200 rounded-lg relative">
          <div className="text-xl font-semibold text-gray-900 pb-8 title">{t('organizationModal.organization')}</div>
          <div className="text-gray-900 content flex flex-wrap gap-8 w-full 2xl:w-1/2">
            <div className="organization-item flex p-3 gap-3 w-full lg:w-1/2 xl:w-1/4">
              <div className="organization-left flex justify-center items-center rounded-md">
                <img src={therapistIcon} alt="icon" />
              </div>
              <div className="organization-item-right">
                <div
                  className="therapists text-xl font-semibold text-gray-900 uppercase">{t('organizationModal.therapists')}</div>
                <div className="flex text-tertiary"><HiOutlineUser
                  className="text-gray-500 h-5 w-5" /> {organization?.countTherapist ?? 0} {organization?.countTherapist > 1 ? t('members') : t('member')}
                </div>
              </div>
            </div>
            <div className="organization-item flex p-3 gap-3 w-full lg:w-1/2 xl:w-1/4 lg:flex-1 xl:flex-none">
              <div className="organization-left flex justify-center items-center rounded-md">
                <HiUsers className="h-[22px] w-auto" />
              </div>
              <div className="organization-item-right">
                <div
                  className="therapists text-xl font-semibold text-gray-900 uppercase">{t('organizationModal.patients')}</div>
                <div className="flex text-tertiary"><HiOutlineUser
                  className="text-gray-500 h-5 w-5 mr-2" /> {organization.countPatient ?? 0} {organization.countPatient > 1 ? t('members') : t('member')}
                </div>
              </div>
            </div>
          </div>
        </div>
        {siteAdmin && (organization.isActive === true || organization.isActive === false) && (
          <div className={`${bgStatusLabel} w-full mt-8 p-8 rounded-lg flex justify-between action`}>
            <div>
              <div className={`text-xl font-semibold ${textStatusLabel} title pb-3 text-header`}>
                {organization.isActive ? t('organizationPage.deactivateTitle') : t('organizationPage.activateTitle')}
              </div>
              <div className={`${textStatusLabel} content`}>
                {organization.isActive ? t('organizationPage.deactivateDescrition') : t('organizationPage.activateDescrition')}
              </div>
            </div>
            <div className="flex items-center justify-end ml-2">
              <Button
                type="button"
                size="sm"
                className={`${bgStatusButton} text-sm`}
                onClick={() => updateStatus.mutate({ id: organizationId, isActive: !organization.isActive })}
              >
                {organization.isActive ? t('deactivate') : t('activate')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrganizationDetail;
